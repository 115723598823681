.bulletin-title {
  text-align: center;
  /*APRIL FOOL'S*/
  /*   background: linear-gradient(
    to left,
    #fea3aa,
    #f8b88b,
    #faf884,
    #baed91,
    #b2cefe,
    #f2a2e8
  ); */
  /*STANDARD*/
  background: #6f4993;
  padding: 1rem 0.5rem;
  border-bottom: 2px solid #d9d9d9;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.bulletin-link {
  /*APRIL FOOL'S*/
  /* color: #20012e; */
  /*STANDARD*/
  color: white;
  font-weight: bold;
  font-size: 1.25em;
}

.bulletin-children {
  padding: 1em;
  box-shadow: inset 2px -2px 0 #d9d9d9, inset -2px 0 0 #d9d9d9;
  display: grid;
  grid-template-columns: 7fr 3fr;
}

.thread-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-date {
  display: block;
  background: #fcfcfc;
  padding-left: 5px;
  font-variant: small-caps;
  font-weight: bold;
  color: #666;
  /*APRIL FOOL'S*/
  /*   font-size: 0.8em; */
  /*STANDARD*/
  font-size: 0.9em;
  text-align: center;
}

.bulletin {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 5fr;
}

.bulletin-children-container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}
