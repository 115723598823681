@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.inline-button {
  padding: 0.5rem 2rem;
  /*APRIL FOOLS*/
  /*   background: linear-gradient(
    to left,
    #fea3aa,
    #f8b88b,
    #faf884,
    #baed91,
    #b2cefe,
    #f2a2e8
  );
  color: #20012e;
  border: 1px solid #20012e; */
  /*STANDARD*/
  background: none;
  color: #6f4993;
  border: 1px solid #6f4399;
  margin: 0.5rem;
}

.inline-button-inverted {
  /*APRIL FOOLS*/
  /*   background: linear-gradient(
    to left,
    #fea3aa,
    #f8b88b,
    #faf884,
    #baed91,
    #b2cefe,
    #f2a2e8
  );
  color: #20012e;
  border: 1px solid #20012e; */
  /*STANDARD*/
  background: #6f4399;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 2rem;
  margin: 0.5rem;
}

.autocomplete-option {
  background: none;
  color: #6f4399;
  padding: 0;
  min-width: 0;
  white-space: normal;
  text-align: left;
}

.floating-button-link {
  /*APRIL FOOL'S*/
  /*   background: linear-gradient(
    to left,
    #fea3aa,
    #f8b88b,
    #faf884,
    #baed91,
    #b2cefe,
    #f2a2e8
  );
  color: #20012e; */
  /*STANDARD*/
  background: #6f4399;
  color: #fff;
  padding: 0.5rem 2rem;
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  border-radius: 35px;
  line-height: 64px;
  box-shadow: 0 6px 10px #888;
  z-index: 100;
}
.floating-button-link:visited,
.floating-button-link:hover,
.floating-button-link:active {
  /*APRIL FOOLS*/
  /*   color: #20012e; */
  /*STANDARD*/
  color: #fff;
}
