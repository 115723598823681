.skeleton {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #e0e0e0 0%, #f8f8f8 50%, #e0e0e0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
}

.skeleton-photo {
  display: block;
  max-height: 300px;
  margin: 0.25em auto;
  border-radius: 2px;
  background: linear-gradient(-90deg, #e0e0e0 0%, #f8f8f8 50%, #e0e0e0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  content: "";

  box-sizing: border-box;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-top-color: #6f4993;
  animation: spinner 0.6s linear infinite;
}

.skeleton-container {
  display: grid;
  grid-template-columns: auto;
}
